.login-form {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: fit-content;
  margin-top: 100px;
}

.login-form * {
  width: 100%;
  text-align: center;
}

.ThreadArt {
  display: block;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid rgba(200, 200, 200);
}

.h-thread {
  width: 100%;
  text-align: center;
  background-color: rgb(220, 200, 200);
}

.editor-r {
  background-color: rgb(240, 240, 240);
  padding: 20px;
}

.search-art {
  width: 100%;
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.input-search {
  display: inline-block;
}

.button-search {
  display: inline-block;
  width: fit-content;
  margin-left: 10px;
}

.search-elem {
  display: block;
  margin-left: auto;
  margin-right: auto;
  grid-row-start: 2;
}

.main {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: auto auto auto auto min-content;
  margin-top: 70px;
}

.main-login {
  display: grid;
  grid-template-rows: 1fr 100%;
  width: 100%;
  height: 100%;
}

.thread-link {
  display: inline-block;
  text-align: right;
  width: 100%;
}

.prev-page {
  display: block;
  margin-top: 20%;
  margin-bottom: 20%;
  padding-right: 4px;
  width: 100%;
  height: 100%;
}

.pagination {
  grid-row-start: 4;
}

.ql-editor {
  min-height: 200px;
}

.list-group {
  grid-row-start: 3;
}

.box {
  grid-template-rows: minauto-content auto;
}

.back {
  background: #e2e2e2;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.div-center {
  width: 400px;
  height: 400px;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  border-bottom: 2px solid #ccc;
  display: table;
}

div.content {
  display: table-cell;
  vertical-align: middle;
}

.banner {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease-in-out;
}

#banner1 {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 20px;
}

#banner2 {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 20px;
}

#banner3 {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 20px;
}

.banner-img {
  max-width: 100%;
  transition: all 0.5s ease-in-out;
}

.container {
  width: 100%;
  max-width: 100%;
}

.IconFontAwesome {
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.IconFontAwesome:hover {
  color: brown;
}

.logo {
  max-width: 50px;
  border-radius: 20%;
}

.ReactTags__tags {
  position: relative;
  padding: 20px;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #eee;
  min-width: 150px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

.ReactTags__selected {
  padding: 10px 0px 10px 00px;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: grid;
}

.spinner {
  width: 100px;
  height: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  border: solid 3px rgb(200, 200, 200);
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagination {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.page-active {
  background-color: #1e50ff !important;
  color: white;
  border: 3px solid #1e50ff !important;
}

.page-active:hover {
  border: 3px solid #b7cfe0 !important;
}

.page-item {
  margin: 1px;
  list-style: none;
  height: 30px;
  width: 30px;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  transition: all 0.1s ease-in-out;
  border: 3px solid #b7cfe0;
}

.page-item > a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.page-item:hover {
  border: 3px solid #1e50ff;
}

.fade-info {
  animation: visible 0.3s ease-in-out;
}

@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tag {
  width: fit-content;
  border-radius: 1%;
}

.tag-delete {
  color: white;
  height: 10px;
  width: 10px;
  display: block;
}

.tag-delete:hover {
  color: red;
}

.next:hover > a > svg {
  color: #aaa !important;
  transition: all 0.3s ease-in-out;
}

.previous:hover > a > svg {
  color: #aaa !important;
  transition: all 0.3s ease-in-out;
}

.color-field {
  width: 80px;
  height: 80px;
}

.red-c {
  padding: 2px;
  width: 20px;
  height: 20px;
  border-radius: 25%;
  background-color: red;
  color: white;
  display: block;
  margin: auto;
}

.red-c > label {
  margin-top: auto;
  margin-bottom: auto;
}

.ql-editor .ql-video {
  width: 640px;
  height: 480px;
}
